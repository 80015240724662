.body-main-image {
  background-color: rgba(167, 167, 167, 0.586);
  z-index: 2;
  position: relative;
}

.image-main-style {
  width: 100%;
  z-index: 1;
  height: 800px;
}

.content-image-main {
  position: absolute;
  top: 22%;
  left: 10%;
  z-index: 3;
}

.slogan-eng {
  font-size: 80px;
  font-weight: bold;
  color: white;
}

.slogan-eng-sub {
  font-size: 80px;
  font-weight: bold;
  color: white;
  margin-left: 130px;
  margin-top: -40px;
}

.slogan-vn {
  margin-top: 40px;
  font-size: 32px;
  font-weight: 600;
  color: white;
}

.overlay-main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    100deg,
    rgba(0, 55, 112, 0.5) 75.82%,
    rgba(1, 124, 250, 0) 94.29%
  );
  z-index: 2;
}

.box-btn-find-out-more {
  margin-top: 50px;
}

.btn-find-out-more {
  width: 306px;
  height: 83px;
  flex-shrink: 0;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  background: #fff;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: 0.3s;
}

.btn-find-out-more:hover {
  width: 306px;
  height: 83px;
  flex-shrink: 0;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  color: white !important;
  background: #004797;
}

.out-solutions {
  margin-top: 80px;
}

.box-title-out-solutions {
  text-align: center;
}

.content-title-out-solutions {
  color: #000;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.hr-title-out-solutions {
  width: 15%;
  margin-top: 10px;
  font-weight: bold;
  background-color: #004797;
  color: #004797;
  height: 4px;
  border-bottom: 2px solid #004797;
}

.hr-title-item-out-solutions {
  width: 88%;
  font-weight: bold;
  background-color: #272727;
  color: #272727;
  height: 1px;
  border-bottom: 1px solid #272727;
}

.hr-title-item-out-solutions-hover-items {
  width: 75%;
  font-weight: bold;
  background-color: #272727;
  color: #272727;
  height: 1px;
  border-bottom: 1px solid #272727;
}

.title-item-out-solu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: center;
}

.content-title-item-out-solu {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.item-col-out-solutions {
  background-color: #ffffff;
  height: 325px;
  cursor: pointer;
  padding-top: 35px;
  transition: 0.5s;
  border-radius: 10px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 20px;
}

.item-col-out-solutions:hover {
  height: 325px;
  padding-left: 65px;
  padding-right: 65px;
  cursor: pointer;
}

.list-out-solutions {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.icon-subtitle {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.style-img-items {
  width: 71.038px;
  height: 80px;
  flex-shrink: 0;
}

.content-subtitle-out-solu {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.box-content-subtitle-out-solu {
  display: flex;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  align-items: center;
  justify-content: center;
}

.content-hover-items {
  text-align: left;
  margin-top: 20px;
  padding-left: 12%;
  padding-right: 12%;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  height: 300px;
  align-content: normal;
}

.sub-title-certification {
  text-align: center;
  color: #494949;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 9%;
  padding-right: 9%;
  margin-top: 40px;
}

.box-btn-contact-us {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.img-certification {
  width: 200px;
  height: 200px;
}

.row-list-image-certi {
  margin-top: 70px;
  padding-left: 10%;
  padding-right: 10%;
}

.col-list-image-certi {
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
}

.title-what-our {
  color: #000;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 8%;
}

.ant-carousel .slick-prev {
  color: black !important;
}

.ant-carousel .slick-next {
  color: black !important;
}

.slick-dots.slick-dots-bottom li {
  background-color: #7c7c7c !important;
  color: #7c7c7c !important;
}

.ant-carousel .slick-dots .slick-active button::after {
  background-color: #397bc5 !important;
  color: #397bc5 !important;
  border-radius: 5px;
}

.box-slide-why {
  margin-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  opacity: 0.9;
  background: rgba(35, 80, 193, 0.04);
}

.slide-why {
  width: 100%;
}

.slide-item {
  display: flex !important;
  padding-top: 40px;
  padding-bottom: 70px;
  justify-content: space-evenly;
  align-items: flex-start;
}

.slide-item-son {
  display: inline-flex;
  max-width: 300px;
  padding: 30px;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px -1px 15.9px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  color: #6d6b6b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.75s;
  height: auto;
  z-index: 2;
}

.slide-item-son:hover {
  box-shadow: 0px -1px 15.9px 0px rgba(5, 96, 215, 0.589);
  transform: translateY(-10px);
}

.slide-item-son.custom {
  margin-top: 10%;
}

.author-slide-item {
  color: #003770;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.address-slide-item {
  color: #9f9b9b;
  font-family: Calibri;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.section-form-contact {
  margin-top: 150px;
  display: table;
  margin-bottom: 150px;
  width: 100%;
}

.img-form-contact {
  position: relative;
  display: table-cell;
  vertical-align: top;
  border: 1px solid rgb(93, 93, 93);
}

.overlay-form-contact-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(24, 24, 24, 0.601) 20.67%,
    rgba(24, 24, 24, 0.601) 20.67%
  );
  mix-blend-mode: plus-darker;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.content-form-contact {
  position: absolute;
  top: 110px;
  text-align: center;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  z-index: 1;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sub-content-form-contact {
  position: absolute;
  text-align: center;
  width: 100%;
  color: #fff;
  top: 245px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  z-index: 1;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-contact {
  border: 1px solid rgb(0, 0, 0);
  height: 400px;
  width: 100%;
  display: table-cell;
  vertical-align: top;
  padding: 50px;
  padding-left: 70px;
  padding-right: 70px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-family: "Roboto", sans-serif;
}

.input-form-contact {
  height: 58px;
  width: 97%;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  padding: 0 15px;
  box-shadow: 0px -1px 15.9px 0px rgba(0, 0, 0, 0.15);
  outline: none;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  margin-bottom: 35px;
}

.input-form-contact:focus {
  box-shadow: 0 4px 12px rgba(0, 55, 112, 0.3);
  border-color: #005780;
}

.input-form-contact::placeholder {
  color: #aaa;
  opacity: 1;
}

.input-contact-details {
  margin-top: 30px;
}

.textarea-form-contact {
  margin-top: 15px;
  width: 97%;
  height: auto;
  padding: 15px;
  font-size: 20px;
  border: none;
  font-family: "Roboto", sans-serif;
  border-radius: 10px;
  box-shadow: 0px -1px 15.9px 0px rgba(0, 0, 0, 0.15);
  outline: none;
  resize: vertical;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.textarea-form-contact:focus {
  box-shadow: 0 4px 12px rgba(0, 55, 112, 0.3);
  border-color: #005780;
}

.textarea-form-contact::placeholder {
  color: #aaa;
  opacity: 1;
}

.requied-field {
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
}

.box-btn-recapcha {
  margin-top: 30px;
  color: #575757;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.checkbox-recapcha {
  margin-left: 10px;
  padding-right: 20px;
}

.box-son-re-capcha {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-submit-form-contact {
  width: 260px;
  height: 83px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #d2d2d2;
  transition: 0.5s;
  color: #5a5a5a;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.btn-submit-form-contact:hover {
  width: 260px;
  height: 83px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #a5a5a5ca;
  color: #5a5a5a;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.footer-form-contact {
  margin-top: 40px;
  color: #575757;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: none;
}

@media (max-width: 768px) {
  .content-image-main {
    position: absolute;
    top: 10%;
    right: 5%;
    left: 5%;
    z-index: 3;
  }

  .slogan-eng {
    font-size: 45px;
    font-weight: bold;
    color: white;
  }

  .slogan-eng-sub {
    margin-top: -20px;
    font-size: 45px;
    font-weight: bold;
    color: white;
    margin-left: 0 !important;
  }

  .slogan-vn {
    margin-top: 40px;
    font-size: 25px;
    font-weight: 600;
    color: white;
  }

  .btn-find-out-more {
    width: 230px;
    height: 70px;
    flex-shrink: 0;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: 0.3s;
  }

  .btn-find-out-more:hover {
    width: 230px;
    height: 70px;
    flex-shrink: 0;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    color: white !important;
    background: #004797;
  }

  .image-main-style {
    width: 100%;
    z-index: 1;
    height: 600px;
  }

  .content-title-out-solutions {
    color: #000;
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  .list-out-solutions {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .item-col-out-solutions {
    background-color: #ffffff;
    min-height: 300px;
    cursor: pointer;
    padding-top: 35px;
    transition: 0.5s;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    margin-bottom: 20px;
  }

  .item-col-out-solutions:hover {
    min-height: 300px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
  }

  .icon-subtitle {
    margin-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }

  .hr-title-item-out-solutions {
    width: 80%;
    font-weight: bold;
    background-color: #272727;
    color: #272727;
    height: 1px;
    border-bottom: 1px solid #272727;
  }

  .content-subtitle-out-solu {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  .box-content-subtitle-out-solu {
    display: flex;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    align-items: center;
    justify-content: center;
  }

  .content-hover-items {
    text-align: left;
    margin-top: 10px;
    padding-left: 4% !important;
    padding-right: 4% !important;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    height: 300px;
    align-content: normal;
  }

  .content-title-item-out-solu {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  .hr-title-item-out-solutions-hover-items {
    width: 65%;
    font-weight: bold;
    background-color: #272727;
    color: #272727;
    height: 1px;
    border-bottom: 1px solid #272727;
  }

  .sub-title-certification {
    text-align: center;
    color: #494949;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 9%;
    padding-right: 9%;
    margin-top: 40px;
  }

  .col-list-image-certi {
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
  }

  .row-list-image-certi {
    margin-top: 70px;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    justify-content: center;
  }

  .section-form-contact {
    margin-top: 150px;
    margin-bottom: 150px;
    width: 100%;
  }

  .content-form-contact {
    position: absolute;
    top: 110px;
    text-align: center;
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .sub-content-form-contact {
    position: absolute;
    text-align: center;
    width: 100%;
    color: #fff;
    top: 245px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .img-form-contact {
    border: 1px solid rgb(93, 93, 93);
  }

  .form-contact-mobile {
    border: 1px solid rgb(0, 0, 0);
    padding: 30px;
    padding-right: 10%;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-family: "Roboto", sans-serif;
  }

  .btn-submit-form-contact {
    width: 260px;
    height: 83px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #d2d2d2;
    transition: 0.5s;
    color: #5a5a5a;
    cursor: pointer;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    margin-top: 15px;
  }

  .box-btn-submit-form-contact {
    display: flex;
    justify-content: center;
  }

  .box-slide-why {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .hr-title-out-solutions {
    width: 40%;
    margin-top: 10px;
    font-weight: bold;
    background-color: #004797;
    color: #004797;
    height: 4px;
    border-bottom: 2px solid #004797;
  }
}
