.section-header {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.main-logo {
  width: 140px;
  height: 120px;
  flex-shrink: 0;
}

.section-header-col {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btn-contact-us {
  width: 175px;
  cursor: pointer;
  height: 62px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #003770;
  background: #004797;
  color: white;
  transition: 0.5s;
}

.btn-contact-us:hover {
  width: 175px;
  cursor: pointer;
  height: 62px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #003770;
  color: white;
  transition: 0.5s;
  background: #0e73e7;
}

.content-btn-contact-us {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.904);
  z-index: 1000;
}

.menu-content {
  position: fixed;
  top: 150px;
  left: 50%;
  width: 100%;
  color: white;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  z-index: 1100;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .section-header-col.menu-res {
    display: none;
  }

  .section-header {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .section-header-col.contact-us {
    padding-left: 10px;
    padding-right: 10px;
  }

  .btn-contact-us {
    width: 100%;
    cursor: pointer;
    height: 40px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #003770;
    background: #004797;
    color: white;
    transition: 0.5s;
  }

  .btn-contact-us:hover {
    width: 100%;
    cursor: pointer;
    height: 50px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #003770;
    color: white;
    transition: 0.5s;
    background: #0e73e7;
  }

  .content-btn-contact-us {
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .main-logo {
    width: 100px;
    height: 80px;
    flex-shrink: 0;
  }

  .btn-contact-us.certification {
    width: 150px;
  }
}
