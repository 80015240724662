.menu {
  font-family: "Roboto Flex", sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: 0.2s;
}

.menu:hover {
  font-family: "Roboto Flex", sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 4px solid rgb(26, 123, 203);
  background-color: rgba(26, 123, 203, 0.1);
}

.menu::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0%;
  height: 4px;
  background-color: rgb(26, 123, 203);
  transition: width 0.3s ease-in-out;
}

.link-menu {
  color: rgb(92, 92, 92);
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.link-menu:hover {
  color: rgb(92, 92, 92);
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.link-menu-mobile {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.link-menu-mobile:hover {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
